<template>
  <div>
<!--导航-->
  <div><van-nav-bar
  title="商品分类"
  left-arrow
@click-left="$router.back(-1)"
  fixed="true"
  ></van-nav-bar>
  </div>
<!--标签-->
  <!---<div style="margin-top:50px"> <van-dropdown-menu>
  <van-dropdown-item :value="value1" :options="option1" />
  <van-dropdown-item :value="value2" :options="option2" />
  <van-dropdown-item :value="value2" :options="option2" />
  <van-dropdown-item :value="value2" :options="option2" />
  </van-dropdown-menu></div>-->
  
   
  <!--商品列表-->
  <div   v-for="item in goods"  :key="item.id"
   :name="item.id">
    <div class="main-goodsList">
    <div class="left-goodsList">
      <div><img style="margin-left:2%;margin-right:2%;width:96%" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1bf45eef3af669f8fd91aa87ad0f54f26b66dc8f8a201e32fb3808e1af56e65f"/></div>
      <div> <div >
          <span> <img  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng059202cad8b3c872aaf489d1616749bd56f7465f66e8fc4b6af166923e51a1a0" 
           @click="$router.push('Demo')" 
           style="width:15px;height:15px"></span>
          <span class="word-goodsList">洪湖湿地湖北麻辣小龙虾尾熟食新鲜虾尾250g加热即食…</span>
      </div></div>

     <div> <span class="info-goodsList">官方旗舰店</span></div>
     <div> <span class="word4-goodsList">¥46.8</span>
     <span  class="info-goodsList" style="text-decoration:line-through">¥53.28</span>
     <span class="info-goodsList" style="  margin: 6px 0 0 7px;">已售11万</span></div>
     <div><span class="span1-goodsList">  
       <span class="word10-goodsList">券</span>
       <span class="word10-goodsList">¥5</span>    </span>
        <span  style=" font-size: 10px; color: rgba(255, 0, 0, 1);background-color: rgba(254, 240, 240, 1); margin-left:20px">分享赚¥53.28</span></div>
    </div>
    </div>
  </div>




  </div>
</template>

<script>
import { Grid,DropdownMenu} from 'vant';

export default {
   components: {
    [Grid.name]: Grid,
     [DropdownMenu.name]: DropdownMenu,
  },
  data() {
    return {
       option1: [
      { text: '全部商品', value: 0 },
      { text: '新款商品', value: 1 },
      { text: '活动商品', value: 2 },
    ],
    option2: [
      { text: '默认排序', value: 'a' },
      { text: '好评排序', value: 'b' },
      { text: '销量排序', value: 'c' },
    ],
    value1: 0,
    value2: 'a',
      checkedGoods: ['1', '2', '3'],
      goods: [{
        id: '1',
        title: '进口香蕉',
        desc: '约250g，2根',
        price: 200,
        num: 1,
        thumb: 'https://img.yzcdn.cn/public_files/2017/10/24/2f9a36046449dafb8608e99990b3c205.jpeg'
      }, {
        id: '2',
        title: '陕西蜜梨',
        desc: '约600g',
        price: 690,
        num: 1,
        thumb: 'https://img.yzcdn.cn/public_files/2017/10/24/f6aabd6ac5521195e01e8e89ee9fc63f.jpeg'
      }, {
        id: '3',
        title: '美国伽力果',
        desc: '约680g/3个',
        price: 2680,
        num: 1,
        thumb: 'https://img.yzcdn.cn/public_files/2017/10/24/320454216bbe9e25c7651e1fa51b31fd.jpeg'
      }]
    };
  },

  computed: {
    submitBarText() {
      const count = this.checkedGoods.length;
      return '结算' + (count ? `(${count})` : '');
    },

    totalPrice() {
      return this.goods.reduce((total, item) => total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0), 0);
    }
  },

  methods: {
    formatPrice(price) {
      return (price / 100).toFixed(2);
    },

    onSubmit() {
      //Toast('点击结算');
    }
  }
};
</script>

<style lang="less" scoped>
.left-goodsList,.right-goodsList{
  float:left; 
  margin-top: 10px;
  margin-left: 1%;
   margin-left: 1%;
   width:48%; height:61px;  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  height: 100%;
  }
.right-goodsList{ float:right; }
.word-goodsList {
  left: 11px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
}
.info-goodsList {
  overflow-wrap: break-word;
  color: rgb(204, 197, 197);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
}
.word4-goodsList {
  overflow-wrap: break-word;
  color: rgba(168, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 1px;
}
.span1-goodsList
{
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4023c11a47ff50499a3b65314f2202aa49749ee6309e8822a346fb8a25334dff)
    100% no-repeat;
	width: 46px;
  height:18px;
	/* 
	使用% 设置宽高以适应父元素的大小
	因为可能得高度坍塌，使用padding-top=（原图高/原图宽）设置高度
	*/
	background-size: cover;
	background-position: center;
	/*
	使用 background-size: cover; 自适应铺满
	background-position: center; 兼容不支持上面语句的浏览器版本
	*/
	display: inline-block;
}
.word10-goodsList {
  margin-left: 5px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: center;
}
</style>
